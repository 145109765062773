<template>
  <b-overlay
    :show="!cargando"
    spinner-variant="primary"
  >
    <b-card>
      <b-form
        ref="form"
        class="repeater-form"
        @submit.prevent="$emit('processForm', items)"
      >
        <!-- Asignatura Info: Input Fields -->
        <b-card-title>
          {{ asignatura.nombreAsignatura }} - {{ asignatura.nombreGrado }}
        </b-card-title>

        <ejesRepeat
          :tieneEjes="asignatura.tieneEjes"
          :objetivos="asignatura.objetivos"
          :items.sync="items"
        />

        <colLinea />

        <!-- Action Buttons -->
        <btnSubmit
          v-if="cargando"
          @processBtn="submitOption"
          :btnText="btnSubmit"
          :btnDisabled="disabledBtnSubmit"
        />
      </b-form>
    </b-card>
  </b-overlay>
</template>

<script>

import {
  BForm, BCardTitle, BOverlay, BCard,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'

import btnSubmit from '../../../components/Form/btnSubmit.vue'
import colLinea from '../../../components/Form/colLinea.vue'
import ejesRepeat from './components/EjesRepeat.vue'

export default {
  components: {
    BForm,
    BCardTitle,
    BOverlay,
    BCard,

    btnSubmit,
    colLinea,
    ejesRepeat,
  },
  computed: {
    ...mapGetters({ getEjesAsignatura: 'ejes/getEjesAsignatura' }),
    disabledBtnSubmit() {
      return false
    },
  },
  props: {
    asignatura: {
      type: Object,
      required: true,
    },
    btnSubmit: {
      type: String, // Texto del boton
      default: 'Guardar Asignatura',
    },
  },
  data() {
    return {
      cargando: true,
      items: [],
    }
  },
  mounted() {
    if (this.asignatura.tieneEjes) {
      this.fetchEjesAsignatura(this.asignatura.id).then(() => {
        this.setItems(this.asignatura.objetivos)
      })
    } else {
      this.items.push({
        id: null,
        nombre: '',
        objetivos: [],
      })
    }
  },
  methods: {
    ...mapActions({ fetchEjesAsignatura: 'ejes/fetchEjesAsignatura' }),
    submitOption() {
      this.$emit('processForm', this.items)
      // this.$v.curso.$touch()
      // if (!this.$v.curso.$invalid) {
      // }
    },
    setItems(asignaturaObjetivos) {
      this.getEjesAsignatura.forEach(ejeAsignatura => {
        const {
          id,
          nombre,
        } = ejeAsignatura

        const objetivosEje = asignaturaObjetivos.filter(o => o.idEje === id)

        const objetivos = []
        objetivosEje.forEach(objetivoEje => {
          const {
            nombreObjetivo,
            abreviatura,
            nombreUnidad,
            priorizacion,
          } = objetivoEje
          const txtPriorizacion = priorizacion != null
            ? `Nivel priorización: ${priorizacion} `
            : ''
          const title = `${txtPriorizacion}( ${nombreUnidad} ) - ${nombreObjetivo}`
          objetivos.push({
            value: objetivoEje.id,
            text: abreviatura,
            title,
            nombreUnidad,
            nombreObjetivo,
            priorizacion,
            disabled: false,
          })
        })

        this.items.push({
          id,
          nombre,
          objetivos,
        })
      })

      /* buscar los ejes de esta asignatura
      * - LISTO hacer un fetch a los ejes con el id asignatura
      * - consultar los ejes
      */

      /* asociarlos al objetivo
      *
      */

      // bloquear objetivos con el idEje
    },
  },
}
</script>
