<template>
  <b-card-code>
    <div>

      <!-- Row Loop -->
      <b-row
        v-for="(item, index) in items"
        :id="item.id"
        :key="item.id"
        ref="row"
      >

        <!-- Nombre Eje -->
        <b-col md="4">
          <b-form-group
            label="Nombre Eje"
            label-for="nombre"
          >
            <b-form-input
              id="nombre"
              v-model="item.nombre"
              type="text"
              placeholder="Ingresa el Nombre de Eje"
            />
          </b-form-group>
        </b-col>

        <!-- Objetivos -->
        <b-col md="6">
          <b-form-group
            label="Objetivos"
            label-for="objetivos"
          >
            <v-select
              v-model="item.objetivos"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              placeholder="Selecciona el o los Objetivos del Eje..."
              label="text"
              :options="objetivosOptions"
              :reduce="option => option.value"
              :selectable="option => !option.disabled"
              @input="seleccionaObjetivos(item)"
            >
              <template #option="{ text, title, priorizacion }">
                <div
                  :title="title"
                >
                  <span>
                    {{ text }}
                  </span>

                  <feather-icon
                    v-if="priorizacion == 1 || priorizacion == 2"
                    icon="StarIcon"
                    size="16"
                    class="align-middle mr-25"
                  />
                  <feather-icon
                    v-if="priorizacion == 2"
                    icon="StarIcon"
                    size="16"
                    class="align-middle mr-25"
                  />
                </div>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <!-- Remove Button -->
        <b-col
          lg="2"
          md="3"
          class="mb-50"
        >
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="mt-0 mt-md-2"
            @click="removeItem(index)"
          >
            <feather-icon
              icon="XIcon"
              class="mr-25"
            />
            <span>Eliminar</span>
          </b-button>
        </b-col>
        <b-col cols="12">
          <hr>
        </b-col>
      </b-row>
    </div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="repeateAgain"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>Nuevo Eje</span>
    </b-button>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BFormGroup, BFormInput, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    objetivos: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    tieneEjes: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      nextTodoId: 2,
      objetivosOptions: [],
    }
  },
  watch: {
    items() {
      if (this.tieneEjes) {
        this.items.forEach(item => {
          this.seleccionaObjetivosUpdate(item)
        })
      }
    },
  },
  mounted() {
    this.initTrHeight()
    this.setObjetivosOptions(this.objetivos)
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    setObjetivosOptions(objetivos) {
      objetivos.forEach(objetivo => {
        const {
          nombreObjetivo,
          abreviatura,
          nombreUnidad,
          priorizacion,
        } = objetivo
        const txtPriorizacion = priorizacion != null
          ? `Nivel priorización: ${priorizacion} `
          : ''
        const title = `${txtPriorizacion}( ${nombreUnidad} ) - ${nombreObjetivo}`
        this.objetivosOptions.push({
          value: objetivo.id,
          text: abreviatura,
          title,
          nombreUnidad,
          nombreObjetivo,
          priorizacion,
          disabled: false,
        })
      })
    },
    seleccionaObjetivos(item) {
      const { objetivos } = item
      objetivos.forEach(objetivo => {
        const objetivoOption = this.objetivosOptions.find(op => op.value === objetivo)
        objetivoOption.disabled = true
      })
    },
    seleccionaObjetivosUpdate(item) {
      const { objetivos } = item
      objetivos.forEach(objetivo => {
        const objetivoOption = this.objetivosOptions.find(op => op.value === objetivo.value)
        objetivoOption.disabled = true
      })
    },

    // Vuexy
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        // this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
