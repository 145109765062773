<template>
  <div v-if="selectedAsignatura">
    <b-overlay
      :show="spinner"
      spinner-variant="primary"
    >
      <b-card>
        <ejesForm
          btnSubmit="Guardar Ejes"
          :asignatura="selectedAsignatura"
          @processForm="guardar"
        />
      </b-card>
    </b-overlay>
  </div>
</template>

<script>

import { BCard, BOverlay } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import ejesForm from './components/Ejes/EjesForm.vue'

export default {
  components: {
    BCard,
    ejesForm,
    BOverlay,
  },
  data() {
    return {
      spinner: false,
    }
  },
  computed: {
    ...mapState('asignaturas', ['selectedAsignatura']),
  },
  methods: {
    ...mapActions({ addEjes: 'ejes/addEjes' }),
    guardar(ejes) {
      const data = {
        idAsignatura: this.selectedAsignatura.id,
        ejes,
      }
      this.spinner = true
      this.addEjes(data).then(() => {
        const errorEjes = store.state.ejes
        const errorMessage = errorEjes.errorMessage.errors
        if (!errorEjes.error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ejes asignados correctamente 👍',
              text: `A la asignatura "${this.selectedAsignatura.nombreAsignatura}"
                se le asignaron correctamente los ejes por objetivo!`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 4000,
          })
          this.$router.replace({
            name: 'asignaturas',
          })
        } else if (errorMessage.nombre) {
          this.$swal({
            title: 'Error!',
            text: `${errorMessage.nombre[0]}!`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else if (errorEjes.error) {
          this.$swal({
            title: 'Error!',
            text: 'Ingreso de datos fraudulento!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
        this.spinner = false
      })
    },
  },
}
</script>
